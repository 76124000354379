import React from 'react';
import { withStyles, Typography,
	Card, CardContent, Button
} from '@material-ui/core';
import Footer from '../Footer';

import aboutHeaderImg from '../../assets/images/aboutHeader.png';
import luisaAvatar from '../../assets/images/Luisa@2x.png';
import danielaAvatar from '../../assets/images/danielaBocanegra.jpg';
import malejaAvatar from '../../assets/images/alejandraGutierrez.jpg';
import nataliaAvatar from '../../assets/images/NataliaBarragán@2x.png'
//import sirleyAvatar from '../../assets/images/SirleyTabio@2x.png';
import jhonAvatar from '../../assets/images/JhonRodríguez@2x.png';
import email from '../../assets/images/ic_mail_outline_24px.svg';

const AboutUs = (props) => {
  return(
    <div className='container'>
      <div className={props.classes.header} style={{backgroundImage: `url(${aboutHeaderImg})`}}>
        <div className='header-container'>
          <Typography variant='h6' className={props.classes.title}>¿Quiénes somos?</Typography><br/>
          + 12 años de experiencia<br/>
          • Expertos en Asesoría, relacionamiento y producción de Eventos<br/><br/>
          <div className={props.classes.aboutUsInfo}>
            <div>
              <p className='section-title'>Objetivo</p>
              <p className='section-body'>
                Diseñar, divulgar y producir eventos de calidad que generen experiencias memorables de<br/>
                alto impacto para las audiencias de interés, aportando a la construcción de comunidad y bienestar.
              </p>
            </div>
            <div>
              <p className='section-body-services'>
                Para más información:
                <a style={{textDecoration: 'none', color: 'white'}} href={'mailto:eventosexperiencias@uniandes.edu.co'}> eventosexperiencias@uniandes.edu.co</a>
              </p>
              {/* <p className='section-title-services'>Servicios</p>
              <p className='section-body-services'>
                • Asesoría y apoyo integral en la producción de eventos virtuales, presenciales y blended, de impacto para la Universidad.<br />
                • Administración, asesoría y capacitaciones del Portal de Eventos.<br />
                • Generación de alianzas colaborativas, internas y externas, para la producción de los eventos que apoyamos desde la jefatura.<br />
                • Préstamo de licencias Zoom y elementos para el desarrollo de los eventos de la Universidad.<br /><br />
                <a
                  href='https://forms.office.com/Pages/ResponsePage.aspx?id=fAS9-kj_KkmLu4-YufucynUiV4Qx1cRGutBxl_xB5vhUN1dNUk84SFc3OURLUjVGOUlWRjhPOUxOQi4u'
                  target='_blank'
                  rel="noopener noreferrer"
                  className={props.classes.registerUrl}
                >
                  <Button variant="contained" color="secondary" className={props.classes.button}>
                    Solicitar un evento
                  </Button>
                </a>
              </p> */}
            </div>
          </div>
        </div>
      </div>
      <Card className={props.classes.cardInfo}>
        <CardContent style={{padding: 'unset'}}>
          <div className={props.classes.containerInfo}>
            <div className='wrapper'>
              <div className='avatar-container'>
                <img src={luisaAvatar} alt='luisa'/>
                <p>
                  <b>Luisa Bastidas</b><br/>
                  Coordinador<br/>
                  lu-basti@uniandes.edu.co
                </p>
              </div>
              <div className='avatar-container'>
                <img src={nataliaAvatar} alt='natalia-barragan'/>
                <p>
                  <b>Natalia Barragan</b><br/>
                  Relacionista Pública<br/>
                  n.barragan@uniandes.edu.co
                </p>
              </div>
              <div className='avatar-container'>
                <img src={malejaAvatar} alt='maria-alejandra-gutierrez'/>
                <p>
                  <b>María Alejandra Gutierrez</b><br/>
                  Relacionista Pública<br/>
                  ma.gutierrez1@uniandes.edu.co
                </p>
              </div>
              <div className='avatar-container'>
                <img src={danielaAvatar} alt='daniela-bocanegra'/>
                <p>
                  <b>Daniela Bocanegra</b><br/>
                  Gestor de Eventos<br/>
                  db.ospina@uniandes.edu.co
                </p>
              </div>
              <div className='avatar-container'>
                <img src={jhonAvatar} alt='jhon-rogriguez'/>
                <p>
                  <b>Jhon Rodríguez</b><br/>
                  Asistente Administrativo y Logístico<br/>
                  je.rodriguezv@uniandes.edu.co
                </p>
              </div>
            </div>
          </div>
        </CardContent>
      </Card>
      <div className={props.classes.contact} >
        <p style={{fontWeight: 'bold'}}>Teléfono: 3394949 ext. 3188 </p>
        <p><a style={{textDecoration: 'none', color: 'white'}} href={'mailto:eventosexperiencias@uniandes.edu.co'}><img src={email} alt='em' style={{marginRight: '10px'}}/>eventosexperiencias@uniandes.edu.co</a></p>
      </div>
      <Footer />
    </div>
  )
}

//1. quitar border
//2. poner position relative
//3.poner div con position absolute y el color
const styleSheet = theme => ({
  header: {
    color: 'white',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    height: '45vh',
    textAlign: 'left',
    display: 'table',
    width: '100%',
    '& .header-container': {
      display: 'table-cell',
      verticalAlign: 'middle',
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(0, 0, 0, 0.7)',
      top: 0,
      padding: '10% 10%'
    }
  },
  title: {
		fontWeight: '700',
		fontFamily: 'Lato, sans-serif',
		fontStyle: 'normal',
		fontSize: '35px',
		[theme.breakpoints.down('sm')]: {
			fontSize: '16px'
		}
  },
  aboutUsInfo: {
    '& div': {
      justifyContent: 'flex-start',
      fontFamily: 'Lato, sans-serif',
      display: 'flex',
      alignItems: 'center',
      [theme.breakpoints.down('sm')]: {
        flexWrap: 'wrap'
      }
    },
    '& div .section-title': {
      color: '#FFF000',
      fontSize: '20px',
      fontFamily: 'Lato, sans-serif',
      fontWeight: 700,
      [theme.breakpoints.down('sm')]: {
        margin: '5px 0',
        fontSize: '16px'
      }
    },
    '& div .section-title-services': {
      color: '#FFF000',
      fontSize: '20px',
      fontFamily: 'Lato, sans-serif',
      fontWeight: 700,
      [theme.breakpoints.down('sm')]: {
        margin: '5px 0',
        fontSize: '16px'
      },
      [theme.breakpoints.up('md')]: {
        marginTop: -40,
      }
    },
    '& div .section-body': {
      fontSize: '15px',
      fontFamily: 'Lato, sans-serif',
      fontWeight: 400,
      paddingLeft: '110px',
      width: '100%',
      lineHeight: '25px',
      [theme.breakpoints.down('sm')]: {
        paddingLeft: 0,
        margin: 'unset'
      }
    },
    '& div .section-body-services': {
      fontSize: '15px',
      fontFamily: 'Lato, sans-serif',
      fontWeight: 400,
      paddingLeft: '188px',
      width: '100%',
      lineHeight: '25px',
      [theme.breakpoints.down('sm')]: {
        paddingLeft: 0,
        margin: 'unset'
      }
    },
    '& div .section-body-vision': {
      fontSize: '15px',
      fontFamily: 'Lato, sans-serif',
      fontWeight: 400,
      paddingLeft: '115px',
      width: '100%',
      lineHeight: '25px',
      [theme.breakpoints.down('sm')]: {
        paddingLeft: 0,
        margin: 'unset'
      }
    }
  },
  cardInfo: {
    margin: '-3% 10% 10%',
    borderRadius: '20px 20px 0 0',
    boxShadow: '0px 1px 20px 0px rgba(0,0,0,0.07), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.06)'
  },
  containerInfo: {
    '& .wrapper': {
      margin: '15px',
      display: 'grid',
      gridTemplateColumns: '1fr 1fr 1fr 1fr',
      [theme.breakpoints.down('sm')]: {
        gridTemplateColumns: '1fr 1fr',
      },
      [theme.breakpoints.down('xs')]: {
        gridTemplateColumns: '1fr',
      },
    },
    '& .wrapper .avatar-container': {
      marginTop: '3%',
      padding: '0.5%',
      flexWrap: 'wrap',
      display: 'flex',
      flexDirection: 'column',
      flexBasis: '19%',
      '& img': {
        height: '145px',
        width: '145px',
        margin: '0 auto'
      },
      '& p': {
        fontSize: '13px',
        fontFamily: 'Lato, sans-serif',
        fontWeight: 400,
        lineHeight: '20px',
        height: '100px',
        '& b': {
          fontSize: '15px'
        }
      }
    },
  },
  contact: {
    backgroundColor: '#1695A3',
    margin: '2% 10%',
    color: 'white',
    display: 'flex',
    justifyContent: 'space-evenly',
    padding: '10px',
    alignItems: 'center',
    fontFamily: 'Lato, sans-serif',
    fontSize: '16px',
    fontWeight: '400',
    [theme.breakpoints.down('sm')] : {
      flexWrap: 'wrap'
    }
  },
  button: {
		backgroundColor: '#B41440 !important',
	},
  registerUrl: {
		textDecoration: 'none',
		fontFamily: 'Lato, sans-serif',
		fontSize: '17px',
		fontWeight: '700'
	}
})
export default withStyles(styleSheet)(AboutUs)